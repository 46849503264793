<template>
  <b-card style="" :class="{ redeemed: isRedeemed }">
    <b-row>
      <b-col md="4">
        <b-img :src="reward.image" alt="Reward Image" width="300px"></b-img>
      </b-col>
      <b-col md="6">
        <b-card-body>
          <h5 class="card-title">{{ reward.name }}</h5>
          <p class="card-text">{{ reward.description }}</p>
          <p class="card-text">
            <strong>{{ reward.points }} Points</strong>
          </p>
          <b-button v-if="reward.is_redeem == 1" @click="redeemReward" :disabled="isRedeemed" variant="gradient-primary">Redeem</b-button>
          <b-button v-else @click="redeemReward" disabled variant="gradient-primary">Redeem</b-button>
          <div v-if="isRedeemed" class="mt-2 text-success">Anda berhasil me-redeem {{ reward.name }}!</div>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BImg, BAvatar, BRow, BCol, BCard, BCardBody, BButton, BOverlay } from "bootstrap-vue";
export default {
  props: {
    reward: {
      type: Object,
      required: true,
    },
    onRedeem: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isRedeemed: false,
    };
  },
  methods: {
    redeemReward() {
      if (!this.isRedeemed) {
        this.onRedeem(this.reward);
        this.isRedeemed = true;
      }
    },
  },
  components: {
    BImg,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BOverlay,
  },
};
</script>

<style scoped>
.redeemed {
  opacity: 0.7;
}
</style>
