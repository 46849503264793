<template>
  <div id="app">
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <b-card>
          <b-row>
            <b-col md="10">
              <div class="d-flex align-items-center justify-content-start">
                <section class="icon">
                  <b-avatar :variant="`light-danger`" size="50">
                    <feather-icon size="24" icon="StopCircleIcon" />
                  </b-avatar>
                </section>
                <section class="info ml-2">
                  <strong class="d-block" style="margin-bottom: 5px"><small>Poin Anda</small></strong>
                  <h2><strong>1405</strong></h2>
                </section>
              </div>
            </b-col>
            <b-col md="2">
              <section class="d-flex align-items-center mt-1">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" @click.prevent="$router.push('/penarikan-komisi')">
                  <feather-icon icon="FileTextIcon" class="mr-50" />
                  Riwayat
                </b-button>
              </section>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <reward-card v-for="reward in rewards" :key="reward.id" :reward="reward" :onRedeem="redeemReward" />
  </div>
</template>

<script>
import RewardCard from "./components/RewardCard.vue";
import { BImg, BAvatar, BRow, BCol, BCard, BCardBody, BButton, BOverlay } from "bootstrap-vue";

export default {
  components: {
    RewardCard,
    BImg,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BOverlay,
  },
  data() {
    return {
      rewards: [
        {
          id: 1,
          name: "Sepeda Motor Honda",
          description: "Anda dapat redeem sepeda motor Honda sesuai dengan point. Harga  asli mulai dari Rp 14 jutaan sampai Rp 18 jutaan. ",
          points: 1300,
          image: require("@/assets/images/reward/motor.jpeg"),
          is_redeem: 1,
        },
        {
          id: 2,
          name: "Mobil Vellfire",
          description: "Harga mobil Vellfire terbaru 2023 mulai Rp1.3 Milyar. Cicilan 16 jutaan dengan DP 50%. Cek spesifikasi Vellfire, redeem jika point sesuai",
          points: 3050,
          image: require("@/assets/images/reward/mobil.jpg"),
          is_redeem: 0,
        },
        // Add more rewards as needed
      ],
    };
  },
  methods: {
    redeemReward(reward) {
      // Handle reward redemption logic (e.g., deduct points, update database)
      console.log(`Anda me-redeem ${reward.name}`);
    },
  },
};
</script>

<style>
/* Add global styling as needed */
</style>
